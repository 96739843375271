
export default {
  data() {
    return {
      slides: [
        {
          // download2E18B0D046DD5C7C8930A786A86CF68E.swf
          img: require('@/assets/slideshow-items/building-video.jpg'),
          headline1: 'In-Game Video',
          headline2: 'Building in the game',
          video: require('@/assets/slideshow-items/building.mp4'),
          videoPos: "B",
          text1: 'LEGO® Universe features building tools to wow any LEGO fan! Make preset models at the push of a button, or build custom creations brick by brick. Bring your creations to life!',
          // link=<a href="http://universe.lego.com">Watch the video</a>
          linkText: 'Watch the video',
          // link2=http://universe.lego.com/en-us/media/default.aspx?section=141010&category=194313&contentID=194359
          link: '/media/s/141010/c/194313/id/194359',
          width: 300,
        }
      ]
    }
  }
}
