<template>
  <div id="mainColumn1">
    <slideshow-module :slides="slides"></slideshow-module>
    <div
      class="column-row column-row-1"
      style="margin-bottom: 80px; height: 537px"
    >
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <br />
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              <!-- UniverseTheGame/images/pic622D8997A7C1FBAB81C31A1A420CE1B4.png -->
              <img style="padding-top: 40px" src="@/assets/the-game/rocket-parts.png" align="right" />
              <h2 style="font-size: 24px">Creation at its best</h2>
              You can have a blast building and creating in LEGO Universe!<br />
              <br />
              When you first enter the game world, choose from hundreds of
              options to build a custom LEGO Minifigure. Then you can modify
              your Minifigure with cool LEGO gear you collect and win on your
              adventures! <br />
              <br />
              Press a button to QuickBuild preset LEGO models during intense
              battle action, or take your time ModularBuilding custom LEGO
              creations like buildings, race cars and spaceships! <br />
              <br />
              To ModularBuild a rocket, for example, you can creatively combine
              pre-made nosecones, cockpits, and tail pieces!<br />
              <br />
              <br />
              <!-- UniverseTheGame/images/picE4427227592B1CEB0A4FE3DFC5970AF8.png -->
              <img src="@/assets/the-game/building.png" align="left" />
              <h2 style="font-size: 24px">You make the rules!</h2>
              Every player gets their own piece of LEGO Universe property! There
              you can FreeBuild anything you like— LEGO brick by brick— and
              bring your creations to life! <br />
              <br />
              Share certain creations only with your friends, or safely build a
              LEGO Universe world that will wow any player! Your property, your
              rules!<br />
              <br />
              To learn more about building in LEGO Universe check out some of
              the articles below:
            </div>
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              •&nbsp;<news-link id="187314"></news-link><br />
              •&nbsp;<news-link id="147678"></news-link><br />
              •&nbsp;<news-link id="144934"></news-link><br />
              •&nbsp;<news-link id="141072"></news-link><br />
              •&nbsp;<news-link id="129539"></news-link><br />
              •&nbsp;<news-link id="127606"></news-link><br />
              •&nbsp;<news-link id="124811"></news-link>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <div
      class="column-row column-row-2"
      style="margin-bottom: 80px; height: 229px"
    >
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <br />
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              <img
                style="padding-top: 40px"
                src="@/assets/the-game/building.png"
                align="right"
              />
              <h2 style="font-size: 24px">Building in LEGO Universe</h2>
              LEGO Universe offers LEGO fans like you many different
              opportunities to build with LEGO bricks. Begin by customizing your
              very own LEGO hero. As you progress in the game, you can collect
              awesome gear, which you can use to make your hero even more
              powerful.<br />
              <br />
              Every player also receives their own piece of LEGO Universe
              property! There you can freely build anything you like and even
              bring your creations to life using lights, colours and movement!
              Share your creations with your friends only, or build a world that
              will wow any player!
              <br />
              <br />
              <br />
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      slides: [
        {
          // download2E18B0D046DD5C7C8930A786A86CF68E.swf
          img: require('@/assets/slideshow-items/building-video.jpg'),
          headline1: 'In-Game Video',
          headline2: 'Building in the game',
          video: require('@/assets/slideshow-items/building.mp4'),
          videoPos: "B",
          text1: 'LEGO® Universe features building tools to wow any LEGO fan! Make preset models at the push of a button, or build custom creations brick by brick. Bring your creations to life!',
          // link=<a href="http://universe.lego.com">Watch the video</a>
          linkText: 'Watch the video',
          // link2=http://universe.lego.com/en-us/media/default.aspx?section=141010&category=194313&contentID=194359
          link: '/media/s/141010/c/194313/id/194359',
          width: 300,
        }
      ]
    }
  }
}
</script>
